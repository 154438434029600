@font-face {
    font-family: "riftlight";
    src:
        url("fort_foundry_-_rift-light-webfont.woff2") format("woff2"),
        url("fort_foundry_-_rift-light-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "pretoriaregular";
    src:
        url("pretoria-webfont.woff2") format("woff2"),
        url("pretoria-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "riftbold";
    src:
        url("fort_foundry_-_rift-bold-webfont.woff2") format("woff2"),
        url("fort_foundry_-_rift-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-mainScreen {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    background-color: #207178;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-topBar {
    display: none;
    gap: 5px;
    padding: 1em;
    grid-template-columns: min-content 1fr min-content;
    z-index: 1;
    background-color: #284557;
    color: #fff;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-topBar label {
    margin-top: 5px;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-topBar svg {
    margin-left: 5px;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-menu {
    flex: 1;
    overflow-y: auto;
    padding-top: 1px;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-menuHeader {
    text-align: center;
    margin: 1em;
    margin-bottom: 0;
    padding: 0.5em;
    padding-right: 4em;
    background-color: #fff;
    color: #284557;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-nombreRestaurante {
    font-family: "pretoriaregular";
    font-size: 80px;
    margin: 1rem;
    margin-bottom: 0;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-slogan {
    font-family: "riftlight";
    font-size: 44px;
    margin: 0;
    margin-top: -.5rem;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-section {
    margin: 1rem;
    margin-top: 0;
    background-color: #fff;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-sectionHeader {
    display: grid;
    gap: 0;
    padding-right: 4em;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-sectionBody {
    padding-bottom: 1rem;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-sectionTitle {
    font-family: "riftlight";
    grid-row: 1;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    margin: 0;
    padding: 2px;
    padding-left: 0.5em;
    padding-right: 0.5em;
    color: #284557;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-sectionDescription {
    margin: auto;
    grid-column: 2;
    grid-row: 1;
    padding: 2px;
    padding-top: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    text-align: center;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-sectionNote {
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-product {
    display: grid;
    gap: 0;
    grid-template-columns: 1fr 2em 1.5em 2em;
    margin-bottom: .5rem;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-product button {
    font-weight: bolder;
    color: #fff;
    background-color: #207178;
    grid-row: 2;
    max-height: 2em;
    max-width: 2em;
    text-align: center;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-product p {
    margin-left: .5rem;
    text-align: center;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-name {
    font-family: "riftlight";
    grid-row: 1;
    font-size: xx-large;
    margin: 0;
    color: #fa721a;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-description {
    font-family: "riftbold";
    grid-row: 2;
    margin: 0;
    color: #0e7b67;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-sidenote {
    margin-left: 0.2em;
    font-size: x-small;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-note {
    font-size: 10px;
    margin-bottom: 4px !important;
    margin-left: 2px;
    grid-column-start: 1;
    grid-column-end: 3;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-price {
    grid-row: 3;
    font-size: large;
    margin: 0;
    margin-top: 0.3rem;
    padding-bottom: 0.2rem;
    color: #284557;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-addButton {
    grid-column: 4;
    border-radius: 0 10px 10px 0;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-quantity {
    grid-column: 3;
    border: none;
    background-color: #fff !important;
    color: #207178 !important;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-removeButton {
    grid-column: 2;
    border-radius: 10px 0 0 10px;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-hidden {
    display: none;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-bottomBar {
    background-color: #284557;
    color: #fff;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-bottomBar button {
    grid-row: auto;
    background-color: #fff;
    color: #284557;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-bottomBar .s5ECFCkoLc9ayCHul3MUgOs4tsrE2-removeButton {
    margin-left: 5px;
    grid-row: 1;
    grid-column: 3;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-bottomBar .s5ECFCkoLc9ayCHul3MUgOs4tsrE2-quantity {
    grid-column: 4;
    background-color: #284557 !important;
    color: #fff !important;
    grid-row: 1;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-bottomBar .s5ECFCkoLc9ayCHul3MUgOs4tsrE2-addButton {
    grid-column: 5;
    grid-row: 1;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-orderLines {
    display: grid;
    row-gap: 0.5em;
    padding: 1em;
    padding-bottom: 0;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-orderLine {
    display: grid;
    margin: 0;
    grid-template-columns: 1fr max-content 2em 1.5em 2em;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-orderLine p {
    margin: 0;
    margin-top: 5px;
    margin-bottom: auto;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-olTotal {
    grid-column: 2;
    text-align: right;
    margin-left: 0;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-horizontalDivider {
    border: 1px solid;
    min-width: 100%;
    margin-top: 2px;
    margin-bottom: 2px;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-totalLine {
    display: grid;
    gap: 0;
    grid-template-columns: 1fr 1fr 2em 1.5em 2em;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-btnConfirmar {
    margin-bottom: 0.5rem;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-totalLine p {
    margin-top: 0;
    margin-bottom: 0;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-lblTotal {
    text-align: left;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-orderTotal {
    text-align: right;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-orders {
    display: grid;
    font-size: large;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    padding: 0.5em;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-orders label:first-child {
    margin-top: 0.5em;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-orders select {
    width: 8rem;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-sumTotal {
    margin: auto;
    text-align: center;
    font-size: x-large;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-fullPage {
    font-family: "riftbold";
    text-align: center;
    background-color: #207178;
    color: #fff;
    padding: 1em;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-fullPage h1 {
    margin: 0;
    padding-top: 1em;
}

.s5ECFCkoLc9ayCHul3MUgOs4tsrE2-btnPagar {
    font-family: "riftbold";
    font-size: x-large;
    color: #284557;
    padding-left: 1rem;
    padding-right: 1rem;
}

#rating {
    display: block;
}

.menuTitle {
    border: none;
    color: #284557;
}

.titulo,
.menuTitle .s5ECFCkoLc9ayCHul3MUgOs4tsrE2-sectionHeader {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.titulo .s5ECFCkoLc9ayCHul3MUgOs4tsrE2-name {
    color: #284557;
    margin: 0;
    margin-top: 1rem;
    font-size: 64px;
    text-align: center;
}

.menuTitle .s5ECFCkoLc9ayCHul3MUgOs4tsrE2-sectionDescription {
    border: black solid 1px;
    border-left: black solid 48px;
    margin: 0;
    margin-bottom: 0.5em;
    order: 1;
    padding: 0.5em;
    padding-top: 0.7em;
    text-align: center;
}

.menuTitle .s5ECFCkoLc9ayCHul3MUgOs4tsrE2-sectionNote {
    border: black 1px solid;
    border-left: black solid 48px;
    font-size: "small";
    margin-bottom: 0;
    margin-top: 1rem;
    order: 3;
    padding: 0.5em;
    padding-left: 1em;
}

.menuTitle .s5ECFCkoLc9ayCHul3MUgOs4tsrE2-sectionTitle {
    order: 2;
    font-size: xx-large;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    background-color: #e2f7df;
}
