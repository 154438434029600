body {
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
}

.form-container {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    max-width: 500px;
    padding: 20px;
}

.form-container h2 {
    color: red;
    text-align: center;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input {
    border-radius: 3px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: 16px;
    padding: 10px;
    width: 100%;
}

.form-group input:focus {
    border-color: red;
    outline: none;
}

.form-group.error input {
    border-color: red;
}

.form-group.error span {
    color: red;
    font-size: 14px;
}

.form-group.error label {
    color: red;
}

.form-group button {
    background-color: red;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
    width: 100%;
}

.form-group button:hover {
    background-color: darkred;
}
