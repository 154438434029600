@font-face {
    font-family: "eastman_alternate_trialbold";
    src:
        url("eastmanalternatetrial-bold-webfont.woff2") format("woff2"),
        url("eastmanalternatetrial-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "trade_gothicmedium";
    src:
        url("trade_gothic_medium-webfont.woff2") format("woff2"),
        url("trade_gothic_medium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "montserrat_alternatesbold";
    src:
        url("montserratalternates-bold-webfont.woff2") format("woff2"),
        url("montserratalternates-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-mainScreen {
    font-family: "trade_gothicmedium";
    font-weight: bold;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    background-image: url(//uqrmecdn.s3.us-east-2.amazonaws.com/u/231969/1679063127.jpg);
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-topBar {
    display: grid;
    gap: 5px;
    padding: 1em;
    grid-template-columns: min-content 1fr min-content;
    z-index: 1;
    background-color: transparent;
    color: #fff;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-topBar label {
    margin-top: 5px;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-topBar svg {
    margin-left: 5px;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-menu {
    flex: 1;
    overflow-y: auto;
    padding-top: 1px;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-menuHeader {
    text-align: center;
    margin: 1em;
    margin-bottom: 0;
    padding: 0.5em;
    background-color: black;
    color: #eaeaea;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-nombreRestaurante {
    font-family: "eastman_alternate_trialbold";
    font-size: xxx-large;
    font-weight: 800;
    margin-bottom: 0.2rem;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-slogan {
    font-size: x-large;
    margin: 0;
    margin-bottom: 1em;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-section {
    margin: 1rem;
    margin-top: 0;
    background-color: #eaeaea;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-sectionHeader {
    display: grid;
    gap: 0;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-sectionBody {
    padding-bottom: 1rem;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-sectionTitle {
    font-size: xx-large;
    grid-row: 1;
    text-align: left;
    margin: 0;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding-left: 0.5rem;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-sectionDescription {
    margin: auto;
    margin-bottom: 1em;
    padding: 2px;
    padding-top: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    text-align: center;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-product {
    display: grid;
    gap: 0;
    grid-template-columns: 1fr max-content 2em 1.5em 2em;
    margin-bottom: 0.5rem;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-product button {
    color: #fff;
    background-color: black;
    grid-row: 1;
    max-height: 2em;
    max-width: 2em;
    text-align: center;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-product p {
    margin-left: 0.5rem;
    text-align: left;
    font-size: 20px;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-name {
    grid-row: 1;
    margin: 0;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-description {
    grid-row: 2;
    margin: 0;
    font-size: 15px !important;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-sidenote {
    margin-left: 0.2em;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-note {
    margin-bottom: 4px !important;
    margin-left: 2px;
    grid-column-start: 1;
    grid-column-end: 3;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-price {
    font-family: "montserrat_alternatesbold";
    font-size: 18px !important;
    grid-row: 1;
    grid-column: 2;
    margin: 0;
    margin-top: 0.3rem;
    padding-bottom: 0.2rem;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-addButton {
    grid-column: 5;
    border-radius: 0 10px 10px 0;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-quantity {
    font-family: "montserrat_alternatesbold";
    grid-column: 4;
    border: none;
    background-color: #eaeaea !important;
    color: black !important;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-removeButton {
    grid-column: 3;
    border-radius: 10px 0 0 10px;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-hidden {
    display: none;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-olName {
    font-size: 20px;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-bottomBar {
    background-color: black;
    color: #fff;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-bottomBar button {
    grid-row: auto;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-bottomBar label {
    font-size: 20px;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-bottomBar .sabsRf0sVMWhPcI0Ao7uATKX4ubB2-removeButton {
    margin-left: 5px;
    grid-row: 1;
    grid-column: 3;
    max-height: 1.5rem;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-bottomBar .sabsRf0sVMWhPcI0Ao7uATKX4ubB2-quantity {
    grid-column: 4;
    background-color: black !important;
    color: white !important;
    grid-row: 1;
    max-height: 1.5rem;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-bottomBar .sabsRf0sVMWhPcI0Ao7uATKX4ubB2-addButton {
    grid-column: 5;
    grid-row: 1;
    margin-right: 5px;
    max-height: 1.5rem;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-orderLines {
    display: grid;
    row-gap: 0.5em;
    padding: 1em;
    padding-bottom: 0;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-orderLine {
    display: grid;
    margin: 0;
    grid-template-columns: 1fr max-content 2em 1.5em 2em;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-orderLine p {
    margin: 0;
    margin-top: 5px;
    margin-bottom: auto;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-olTotal {
    font-family: "montserrat_alternatesbold";
    font-size: 18px;
    grid-column: 2;
    text-align: right;
    margin-left: 0;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-horizontalDivider {
    border: 1px solid;
    min-width: 100%;
    margin-top: 2px;
    margin-bottom: 2px;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-totalLine {
    display: grid;
    gap: 0;
    grid-template-columns: 1fr 1fr 2em 1.5em 2em;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-btnConfirmar {
    font-size: 18px;
    margin-bottom: 0.5rem;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-totalLine p {
    margin-top: 0;
    margin-bottom: 0;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-lblTotal {
    text-align: left;
    font-size: 20px;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-orderTotal {
    font-family: "montserrat_alternatesbold";
    font-size: 18px;
    text-align: right;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-orders {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    padding: 0.5em;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-orders label:first-child {
    margin-top: 0.5em;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-orders select {
    width: 8rem;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-sumTotal {
    font-family: "montserrat_alternatesbold";
    margin: auto;
    text-align: center;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-fullPage {
    text-align: center;
    background-color: #eaeaea;
    color: black;
    padding: 1em;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-fullPage h1 {
    margin: 0;
    padding-top: 1em;
}

.sabsRf0sVMWhPcI0Ao7uATKX4ubB2-btnPagar {
    font-size: 18px;
    color: black;
    padding-left: 1rem;
    padding-right: 1rem;
}

#rating {
    display: block;
}
