.tables {
    display: grid;
    gap: 2em;
    margin: 1em;
}

@media only screen and (min-width: 680px) {
    .tables {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 1024px) {
    .tables {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 1360px) {
    .tables {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.table {
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    border: 1px outset #178390;
    border-radius: 4px;
    background-color: #178390;
}

.tableHeader {
    padding: 0.5rem;
    font-size: x-large;
    font-weight: bolder;
    color: #f6f9ee;
}

.callingWaiterIcon {
    float: right;
}

@keyframes flickerAnimation {
    /* flame pulses */
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.animateFlicker {
    opacity: 1;
    animation: flickerAnimation 2s infinite;
}

.tableFooter {
    font-size: x-large;
    font-weight: bolder;
    color: #f6f9ee;
    display: grid;
    grid-template-columns: 1fr auto;
    row-gap: 0;
    padding: 0.5rem;
}

.tableFooter button {
    margin: 1em;
}

.tableBody {
    display: grid;
    background-color: #f6f9ee;
}

.order {
    padding: 1em;
    border-bottom: 1px solid #178390;
    font-weight: bolder;
}

.order:last-child {
    border: none;
}

.order p {
    float: left;
}

.order ul {
    margin: 0.75em;
}

.order span {
    float: right;
}
